import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Box, Typography, Chip } from '@mui/material';

const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_URL;

function ManufactPopup({ open, onClose, onManufactSelect }) {
    const [manufacts, setManufacts] = useState([]); // 제조사 목록
    const [searchText, setSearchText] = useState(''); // 검색 텍스트
    const [filteredManufacts, setFilteredManufacts] = useState([]); // 필터링된 제조사 목록
    const [selectedManufacts, setSelectedManufacts] = useState([]); // 선택된 제조사 목록

    useEffect(() => {
        const fetchManufacts = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/manufact`);
                const data = await response.json();
                setManufacts(data);
                setFilteredManufacts(data); // 초기값 설정
            } catch (error) {
                console.error('Error fetching manufacturers:', error);
            }
        };

        fetchManufacts();
    }, []);

    const handleSearchChange = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);

        const filtered = manufacts.filter((manufact) =>
            manufact.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredManufacts(filtered);
    };

    const handleManufactSelect = (manufact) => {
        if (!selectedManufacts.some((item) => item._id === manufact._id)) {
            setSelectedManufacts((prev) => [...prev, manufact]); // 선택된 목록에 추가
            onManufactSelect(manufact); // 부모 컴포넌트에 전달
        }
    };

    const handleManufactRemove = (manufact) => {
        setSelectedManufacts((prev) => prev.filter((item) => item._id !== manufact._id)); // 선택된 목록에서 제거
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>제조사 선택</DialogTitle>
            <DialogContent>
                {/* 선택된 제조사 출력 */}
                <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                    {selectedManufacts.map((manufact) => (
                        <Chip
                            key={manufact._id}
                            label={manufact.name}
                            onDelete={() => handleManufactRemove(manufact)} // 선택 항목 제거
                            color="primary"
                        />
                    ))}
                </Box>

                {/* 검색 입력 */}
                <TextField
                    label="제조사 검색"
                    value={searchText}
                    onChange={handleSearchChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />

                {/* 제조사 리스트 */}
                <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {filteredManufacts.length === 0 ? (
                        <Typography variant="body2" color="textSecondary">
                            No manufacturers found.
                        </Typography>
                    ) : (
                        filteredManufacts.map((manufact) => (
                            <MenuItem
                                key={manufact._id}
                                onClick={() => handleManufactSelect(manufact)}
                            >
                                {manufact.name}
                            </MenuItem>
                        ))
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">닫기</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ManufactPopup;
