import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Box, Typography, Chip } from '@mui/material';
const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_URL;

function CategoryPopup({ open, onClose, onCategorySelect }) {
    const [categorys, setCategorys] = useState([]); // 제조사 목록
    const [searchText, setSearchText] = useState(''); // 검색 텍스트
    const [filteredCategorys, setFilteredCategorys] = useState([]); // 필터링된 제조사 목록
    const [selectedCategorys, setSelectedCategorys] = useState([]); // 선택된 제조사 목록

    useEffect(() => {
        const fetchCategorys = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/categories`);
                const data = await response.json();
                setCategorys(data);
                setFilteredCategorys(data); // 초기값 설정
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategorys();
    }, []);

    const handleSearchChange = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);

        const filtered = categorys.filter((category) =>
            category.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredCategorys(filtered);
    };

    const handleCategorySelect = (category) => {
        if (!selectedCategorys.some((item) => item._id === category._id)) {
            setSelectedCategorys((prev) => [...prev, category]); // 선택된 목록에 추가
            onCategorySelect(category); // 부모 컴포넌트에 전달
        }
    };

    const handleCategoryRemove = (category) => {
        setSelectedCategorys((prev) => prev.filter((item) => item._id !== category._id)); // 선택된 목록에서 제거
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>카테고리</DialogTitle>
            <DialogContent>
                {/* 선택된 카테고리 */}
                <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                    {selectedCategorys.map((category) => (
                        <Chip
                            key={category._id}
                            label={category.name}
                            onDelete={() => handleCategoryRemove(category)} // 선택 항목 제거
                            color="primary"
                        />
                    ))}
                </Box>

                {/* 검색 입력 */}
                <TextField
                    label="카테고리 검색"
                    value={searchText}
                    onChange={handleSearchChange}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                />

                {/* 카테고리 리스트 */}
                <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {filteredCategorys.length === 0 ? (
                        <Typography variant="body2" color="textSecondary">
                            No category found.
                        </Typography>
                    ) : (
                        filteredCategorys.map((category) => (
                            <MenuItem
                                key={category._id}
                                onClick={() => handleCategorySelect(category)}
                            >
                                {category.name}
                            </MenuItem>
                        ))
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">닫기</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CategoryPopup;
