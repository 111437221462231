import { Box, Typography } from '@mui/material';

// 페이지 하단 고정 컴포넌트
function Footer() {
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                backgroundColor: '#f2f2f2', // 회색 배경
                color: '#000',
                padding: 2,
                boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
                height: '80px', // 고정된 바의 높이 설정
            }}
        >
            <Typography variant="body2" gutterBottom>
                <strong>주식회사 지셈</strong> | 대표: 박수호 | 사업자번호 301-86-29336
            </Typography>
            <Typography variant="body2" gutterBottom>
                충북 청주시 상당로 314 첨단문화산업단지 <br /> 전화: 010-8034-5893
            </Typography>

        {/*    <Typography variant="body2" sx={{ color: '#1976d2', fontSize: '10px' }}>*/}
        {/*        지셈은 통신판매 중개자로서 통신판매의 당사자가 아니며<br />*/}
        {/*        상품 및 거래정보, 거래에 대하여 책임을 지지 않습니다.*/}
        {/*    </Typography>*/}
        </Box>
    );
}

export default Footer;
