

import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    Typography,
    Chip,
} from '@mui/material';
import CategoryPopup from './CategoryPopup'; // CategoryPopup 컴포넌트를 가져옴
import ManufactPopup from './ManufactPopup';
function SearchPopup({ open, onClose, onSearch, isAdmin }) {
    const [criteria, setCriteria] = useState({
        category: [], // 복수  저장
        manufacturers: [], // 복수 제조사 저장
        model: '',
        minPrice: '',
        maxPrice: '',
        minPower: '',
        maxPower: '',
        minYear: '',
        maxYear: '',
    });

    const [isCategoryPopupOpen, setCategoryPopupOpen] = useState(false);
    const [isManufactPopupOpen, setManufactPopupOpen] = useState(false);

    const isSubMenuDisabled = criteria.model.trim() !== '';
    


    const formatNumber = (value) => {
        if (!value) return '';
        // 숫자만 추출 후 쉼표 포맷
        return parseInt(value.replace(/,/g, ''), 10).toLocaleString();
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCriteria((prev) => ({ ...prev, [name]: value }));
        
    };
    const handleChangePrice = (e) => {
        const { name, value } = e.target;

        if (/^[\d,]*$/.test(value)) {
            setCriteria((prev) => ({ ...prev, [name]: formatNumber(value) }));
        }
        else {
            setCriteria((prev) => ({ ...prev, [name]: value }));
        }

    };


    const handleCategorySelect = (category) => {
        setCriteria((prev) => ({
            ...prev,
            category: [...prev.category, category.name], // 복수 추가
        }));
    };

    const handleCategoryRemove = (categoryName) => {
        setCriteria((prev) => ({
            ...prev,
            category: prev.category.filter((name) => name !== categoryName),
        }));
    };


    const handleManufactSelect = (manufact) => {
        setCriteria((prev) => ({
            ...prev,
            manufacturers: [...prev.manufacturers, manufact.name], // 복수 추가
        }));
    };

    const handleManufactRemove = (manufactName) => {
        setCriteria((prev) => ({
            ...prev,
            manufacturers: prev.manufacturers.filter((name) => name !== manufactName),
        }));
    };

    const handleSubmit = () => {

        criteria.model = "";
        onSearch(criteria); // 부모 컴포넌트로 검색 조건 전달
        onClose(); // 팝업 닫기
    };
    const handleSubmitModel = () => {
        var criteria2 = {};
        criteria2.model = criteria.model;

        onSearch(criteria2); // 부모 컴포넌트로 검색 조건 전달
        onClose(); // 팝업 닫기
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>세부 검색</DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2}>
                    {/* 모델명 입력 */}
                    <Box display="flex" sx={{ mt: 0, mb: 1 }} >
                        <TextField
                            label="모델명"
                            name="model"
                            value={criteria.model}
                            onChange={handleChange}
                            fullWidth
                        />
                        <Button onClick={handleSubmitModel} variant="contained" color="primary">
                            검색
                        </Button>
                    </Box>
                    {/* 카테고리 */}
                    {/*<Box>*/}
                    {/*    <Typography variant="subtitle1" sx={{ mb: 1 }}>*/}
                    {/*        카테고리*/}
                    {/*    </Typography>*/}
                    {/*    {criteria.category ? (*/}
                    {/*        <Chip*/}
                    {/*            label={criteria.category}*/}
                    {/*            onDelete={handleCategoryClear} // X 버튼 클릭 시 초기화*/}
                    {/*            color="primary"*/}
                    {/*        />*/}
                    {/*    ) : (*/}
                    {/*        <Button*/}
                    {/*            variant="outlined"*/}
                    {/*            fullWidth*/}
                    {/*                onClick={() => setCategoryPopupOpen(true)}*/}
                    {/*            disabled={isSubMenuDisabled} // 모델명 입력시 비활성화*/}
                    {/*        >*/}
                    {/*            {criteria.category || '카테고리 선택'}*/}
                    {/*        </Button>*/}
                    {/*    )}*/}
                    {/*</Box>*/}
                    {isAdmin &&
                        (<Box>
                            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                카테고리
                            </Typography>
                            <Box display="flex" flexWrap="wrap" gap={1}>
                                {criteria.category.map((name) => (
                                    <Chip
                                        key={name}
                                        label={name}
                                        onDelete={() => handleCategoryRemove(name)} // 선택된 제조사 삭제
                                        color="primary"
                                    />
                                ))}
                                <Button
                                    variant="outlined"
                                    onClick={() => setCategoryPopupOpen(true)}
                                    
                                >
                                    카테고리 선택
                                </Button>
                            </Box>
                        </Box>)
                        }

                    {/* 제조사 선택 */}
                    <Box>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            제조사
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                            {criteria.manufacturers.map((name) => (
                                <Chip
                                    key={name}
                                    label={name}
                                    onDelete={() => handleManufactRemove(name)} // 선택된 제조사 삭제
                                    color="primary"
                                />
                            ))}
                            <Button
                                variant="outlined"
                                onClick={() => setManufactPopupOpen(true)}
                                
                            >
                                제조사 선택
                            </Button>
                        </Box>
                    </Box>

                    {/* 가격대 입력 */}

                    <Typography variant="subtitle1" sx={{ mt: 0, mb: 0 }}>
                        가격(단위 원)
                    </Typography>

                    <Box display="flex" gap={2}>
                        <TextField
                            label="최소 가격"
                            name="minPrice"
                            value={criteria.minPrice}
                            onChange={handleChangePrice}
                            fullWidth
                            
                        />
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                              ~   
                        </Typography>
                        <TextField
                            label="최대 가격"
                            name="maxPrice"
                            value={criteria.maxPrice}
                            onChange={handleChangePrice}
                            fullWidth
                            
                        />
                    </Box>

                    {/* 마력대 입력 */}
                    <Typography variant="subtitle1" sx={{ mt: 0, mb: 0 }}>
                        마력(POWER)
                    </Typography>

                    <Box display="flex" gap={2}>
                        <TextField
                            label="최소 마력"
                            name="minPower"
                            type="number"
                            value={criteria.minPower}
                            onChange={handleChange}
                            fullWidth
                            
                        />
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            ~
                        </Typography>


                        <TextField
                            label="최대 마력"
                            name="maxPower"
                            type="number"
                            value={criteria.maxPower}
                            onChange={handleChange}
                            fullWidth
                            
                        />
                    </Box>

                    <Typography variant="subtitle1" sx={{ mt: 0, mb: 0 }}>
                        제조년도
                    </Typography>

                    {/* 제조년도 입력 */}
                    <Box display="flex" gap={2}>
                        <TextField
                            label="최소 제조년도"
                            name="minYear"
                            type="number"
                            value={criteria.minYear}
                            onChange={handleChange}
                            fullWidth
                            
                        />
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                            ~
                        </Typography>

                        <TextField
                            label="최대 제조년도"
                            name="maxYear"
                            type="number"
                            value={criteria.maxYear}
                            onChange={handleChange}
                            fullWidth
                            
                        />
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    닫기
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    검색
                </Button>
            </DialogActions>
            {/* 카테고리 팝업 */}
            <CategoryPopup
                open={isCategoryPopupOpen}
                onClose={() => setCategoryPopupOpen(false)}
                onCategorySelect={handleCategorySelect}
            />
            {/* 제조사 팝업 */}
            <ManufactPopup
                open={isManufactPopupOpen}
                onClose={() => setManufactPopupOpen(false)}
                onManufactSelect={handleManufactSelect}
            />
        </Dialog>
    );
 
}

export default SearchPopup;
